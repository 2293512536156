import React from 'react';
import { Button as ButtonComponent } from '@mui/material';
import buttonStyles from '../../../stylesheets/components/buttons.module.scss';
import classNames from 'classnames';

interface LoginButtonProps {
  id?: string;
  dataId?: string;
  onClick: () => void;
  logoPath: string;
  children: React.ReactNode;
}

const Button = ({
  id,
  dataId,
  onClick,
  logoPath,
  children,
}: LoginButtonProps) => {
  return (
    <ButtonComponent
      id={id}
      data-id={dataId}
      className={classNames(buttonStyles.button, buttonStyles.social)}
      onClick={onClick}
    >
      <>
        <img src={logoPath} className={buttonStyles.logo} />
        {children}
      </>
    </ButtonComponent>
  );
};

export default Button;
